<template>
    <v-dialog
        v-if="visible"
        v-model="visible"
        transition="dialog-top-transition"
        scrollable
        persistent
        max-width="600"
    >
        <v-card class="modal-maincard">

            <v-toolbar dark flat dense>
                <v-toolbar-title>{{ $t(title) }}</v-toolbar-title>
            </v-toolbar>

            <v-card-text
                class="wrapperFormModal"
            >
                <v-card 
                    flat
                >
                    <v-card-text>

                        <v-row no-gutters>

                            <v-col cols="12" sm="12" md="3">
                                <label class="f-label">{{$t("Причина")}}</label>
                            </v-col>

                            <v-col cols="12" sm="12" md="9">
                                <v-textarea
                                    v-model="reason"
                                    rows="3"
                                    no-resize
                                    hide-details
                                    required
                                    outlined
                                    dense
                                >
                                </v-textarea>
                            </v-col>

                        </v-row>

                    </v-card-text>
                </v-card>
            </v-card-text>

            <v-card-actions>
                <v-spacer></v-spacer>

                <v-btn 
                    color="cyan"
                    text
                    depressed
                    @click="ok"
                    v-if="valid"
                >
                    {{ $t("Вернуть") }} 
                </v-btn>

                <v-btn
                    color="blue-grey"
                    text
                    depressed
                    @click="cancel"
                >
                    {{ $t("Отмена") }}
                </v-btn>

            </v-card-actions>

        </v-card>
    </v-dialog>
</template>

<script>
import { mapActions, mapGetters } from 'vuex';

export default {
    name: "ReturnReasonDlg",
    data () {
        return {
            title: "Возврат_отчета_на_доработку",

            options: {
                color: 'grey lighten-3',
                width: 400,
                zIndex: 200,
                noconfirm: false,
            }
        }
    },
    computed: {
        ...mapGetters('dialogs/returnreason', { visible: 'isVisible', valid: 'isValid' }),
        reason: {
            get: function() {
                return this.$store.getters['dialogs/returnreason/getReason'];
            },
            set: function(v) {
                this.$store.commit('dialogs/returnreason/SET_REASON', v); 
            }
        },
    },
    methods: {
        ...mapActions('dialogs/returnreason', ['ok', 'cancel'])
    }
}
</script>